import { toNormalizedString } from '../../../../play-core/utils/to-seo-string';
export const numericGroup = '#0-9';
export const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
export const letters = [...alphabet, numericGroup];
export const groupAndFilterShows = (shows, filter) => {
  const groupedShows = {};
  const filteredShows = shows.filter(show => !filter || show.title.toLowerCase().includes(filter.trim().toLowerCase()));
  filteredShows.forEach(show => {
    const firstLetter = toNormalizedString(show.title).charAt(0);
    const letter = alphabet.includes(firstLetter) ? firstLetter : numericGroup;
    if (!groupedShows[letter]) {
      groupedShows[letter] = [];
    }
    groupedShows[letter].push(show);
  });
  return groupedShows;
};