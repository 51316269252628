import { useEffect } from 'react';
import { useLocation, useNavigate } from "@remix-run/react";
import { toQueryString } from '../../../../play-core/utils/request-url-helper';
export const indexParam = 'index';
export const filterParam = 'filter';
export const onlyActiveShowsParam = 'onlyActiveShows';
export const useUrlSync = (filter, index, onlyActiveShows) => {
  const navigate = useNavigate();
  const {
    pathname,
    search
  } = useLocation();
  useEffect(() => {
    const params = getParams(search);
    const currentLocation = pathname + search;
    if (params.index !== index || params.filter !== filter || params.onlyActiveShows !== onlyActiveShows) {
      const queryString = toQueryString({
        filter,
        index,
        onlyActiveShows
      });
      const newLocation = `${pathname}${queryString}`;
      if (currentLocation !== newLocation) {
        navigate(newLocation, {
          replace: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, filter, onlyActiveShows]);
};
export const getParams = search => {
  const params = new URLSearchParams(search);
  const index = params.get(indexParam) || undefined;
  const filter = params.get(filterParam) || undefined;
  const onlyActiveShows = params.get(onlyActiveShowsParam) || undefined;
  return {
    index,
    filter,
    onlyActiveShows
  };
};